import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { mixesInitialState, formatThunkError } from '../Utils/store'
import { createMix, retrieveMixes } from '../Api/mixes'

interface BaseThunk {
  token: string
}

export const getMixes = createAsyncThunk(
  '/mixes (GET)',
  async ({ token }: BaseThunk, thunkApi) => {
    try {
      const response = await retrieveMixes(token)

      return response.data
    } catch (e) {
      const error = formatThunkError(e, 'getMixes error')

      return thunkApi.rejectWithValue(error)
    }
  }
)

type PostMixes = BaseThunk & {
  body: CreateMixPostBody
}
export const postMixes = createAsyncThunk(
  '/mixes (POST)',
  async ({ token, body }: PostMixes, thunkApi) => {
    try {
      const response = await createMix(token, body)

      return response.data
    } catch (e) {
      const error = formatThunkError(e, 'postMixes error')

      return thunkApi.rejectWithValue(error)
    }
  }
)

export const mixes = createSlice({
  name: 'mixes',
  initialState: mixesInitialState,
  reducers: {
    clearMixesState: () => mixesInitialState,
    clearGetAllStatus: (state) => {
      state.getStatus = 'idle'
    },
    clearErrorMessage: (state) => {
      state.errorMessage = ''
    },
    setErrorMessage: (state, action: { payload: string }) => {
      state.errorMessage = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getMixes.pending, (state) => {
      state.getStatus = 'loading'
    })
    builder.addCase(getMixes.rejected, (state, action) => {
      state.errorMessage = Boolean(action.error) && typeof action.error === 'string'
        ? action.error
        : action.payload as string
      state.mixes = mixesInitialState.mixes
      state.getStatus = 'error'
    })
    builder.addCase(getMixes.fulfilled, (state, action) => {
      state.mixes = action.payload
      state.getStatus = 'success'
    })
    builder.addCase(postMixes.pending, (state) => {
      state.postStatus = 'loading'
    })
    builder.addCase(postMixes.rejected, (state, action) => {
      state.errorMessage = Boolean(action.error) && typeof action.error === 'string'
        ? action.error
        : action.payload as string
      state.mix = mixesInitialState.mix
      state.postStatus = 'error'
    })
    builder.addCase(postMixes.fulfilled, (state, action) => {
      state.mix = action.payload
      state.postStatus = 'success'
    })
  }
})

export const {
  clearMixesState,
  clearGetAllStatus,
  clearErrorMessage,
  setErrorMessage
} = mixes.actions

export const selectGetMixesStatus = (state: State): State['mixesInfo']['getStatus'] => state.mixesInfo.getStatus
export const selectPostMixesStatus = (state: State): State['mixesInfo']['postStatus'] => state.mixesInfo.postStatus
export const selectErrorMessage = (state: State): State['mixesInfo']['errorMessage'] => state.mixesInfo.errorMessage
export const selectMixes = (state: State): State['mixesInfo']['mixes'] => state.mixesInfo.mixes
export const selectMix = (state: State): State['mixesInfo']['mix'] => state.mixesInfo.mix
