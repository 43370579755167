import { useCallback, useEffect, type FC } from 'react'
import { type GridColDef } from '@mui/x-data-grid'
import { useKindeAuth } from '@kinde-oss/kinde-auth-react'
import { useAppDispatch, useAppSelector } from '../Utils/store'
import { getMixes, selectMixes, setErrorMessage } from '../Store/mixes'
import { Box, Button, Paper, Typography, useTheme } from '@mui/material'
import { setComponent } from '../Store/menu'
import { MusicNote } from '@mui/icons-material'
import CustomTable from './CustomTable'

const Mixes: FC = () => {
  const { getToken } = useKindeAuth()
  const dispatch = useAppDispatch()
  const mixes = useAppSelector(selectMixes)
  const goToMix = useCallback(async () => {
    dispatch(setComponent('mix'))
  }, [dispatch])
  const theme = useTheme()

  useEffect(() => {
    (async () => {
      try {
        if (getToken != null) {
          const token = await getToken()
          if (token != null) {
            await dispatch(getMixes({ token }))
          }
        }
      } catch (e) {
        dispatch(setErrorMessage(typeof e === 'string' ? e : String(e)))
      }
    })()
      .catch(e => { dispatch(setErrorMessage(typeof e === 'string' ? e : String(e))) })
  }, [dispatch, getToken])

  const columns: GridColDef[] = [
    { field: 'a_track_title', headerName: 'Track A' },
    { field: 'b_track_title', headerName: 'Track B' },
    { field: 'mix_datetime', headerName: 'Date & Time' },
    { field: 'a_offset', headerName: 'A Offset' },
    { field: 'b_offset', headerName: 'B Offset' },
    { field: 'mix_type', headerName: 'Type' },
    { field: 'weight', headerName: 'Weight', type: 'number' },
    { field: 'delta_danceability', headerName: '∆ Danceability', type: 'number' },
    { field: 'delta_energy', headerName: '∆ Energy', type: 'number' },
    { field: 'delta_BPM', headerName: '∆ BPM', type: 'number' },
    { field: 'delta_genre', headerName: '∆ Genre' },
    { field: 'direction', headerName: 'Direction', width: 100, valueGetter: (params) => params === null ? 'Unset' : params === true ? 'Forward' : 'Backward' },
    { field: 'emotion', headerName: 'Emotion' }
  ]

  return <Paper
    sx={{
      width: '100%',
      bgcolor: theme.palette.secondary.light,
      color: theme.palette.primary.main,
      padding: 2,
      boxShadow: theme.shadows[3]
    }}
  >
    {
      mixes.length > 0
        ? <CustomTable rows={mixes.map(row => ({ id: row.mix_id, ...row }))} columns={columns} type='mix' />
        : <Box sx={{
          justifySelf: 'center',
          textAlign: 'center',
          padding: '8vh 5vw',
          bgcolor: theme.palette.secondary.main,
          width: '55%',
          borderRadius: '2%'
        }}>
          <Typography variant="h6">Non ci sono mix disponibili.</Typography>
          <Typography variant="body1" sx={{ marginBottom: 2 }}>
            Crea il tuo primo mix per iniziare!
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => { void goToMix() }}
            sx={{ fontWeight: 800, boxShadow: 10, width: '40%', maxWidth: '220px' }}
            data-testid="create-mix"
            endIcon={<MusicNote />}
          >
            Crea un nuovo mix
          </Button>
        </Box>
    }
  </Paper >
}

export default Mixes
