import { Fragment, useCallback, useState, type FC, type ReactNode } from 'react'
import { IconButton, Box } from '@mui/material'
import { GridFooterContainer, GridFooter, useGridApiContext, useGridSelector, gridRowSelectionStateSelector } from '@mui/x-data-grid'
import { Delete, Edit } from '@mui/icons-material'

interface FooterSelectionBaseProps {
  renderDialog: (selectedRow: Mix | CustomTrack | null, dialogOpen: boolean, onClose: () => void) => ReactNode
}

const FooterSelectionBase: FC<FooterSelectionBaseProps> = ({ renderDialog }) => {
  const apiRef = useGridApiContext()
  const selectedRowIds = useGridSelector(apiRef, gridRowSelectionStateSelector)
  const selectedRows = selectedRowIds.map((id) => apiRef.current.getRow(id))

  const [dialogOpen, setDialogOpen] = useState(false)
  const [selectedRow, setSelectedRow] = useState<Mix | CustomTrack | null>(null)

  const handleDelete = useCallback(() => {
    console.log('Cancello le righe:', selectedRows)
  }, [selectedRows])

  const handleEdit = useCallback(() => {
    if (selectedRows.length === 1) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      setSelectedRow(selectedRows[0])
      setDialogOpen(true)
    } else {
      console.log('Seleziona una sola riga per modificare')
    }
  }, [selectedRows])

  const closeDialog = useCallback(() => {
    setDialogOpen(false)
    setSelectedRow(null)
  }, [])

  return <Fragment>
    <GridFooterContainer
      data-testid="GridFooterContainer"
      sx={{
        flexDirection: 'row',
        width: '100%',
        justifyContent: selectedRows.length > 0 ? 'space-between' : 'flex-end'
      }}
    >
      {selectedRows.length > 0 && (
        <Box sx={{ display: 'flex', p: 1 }}>
          <IconButton color="primary" sx={{ mr: 1 }} onClick={handleDelete}>
            <Delete />
          </IconButton>
          <IconButton color="primary" onClick={handleEdit}>
            <Edit />
          </IconButton>
        </Box>
      )}
      <GridFooter />
    </GridFooterContainer>
    {renderDialog(selectedRow, dialogOpen, closeDialog)}
  </Fragment>
}

export default FooterSelectionBase
