import { type AxiosResponse } from 'axios'
import { createApiHeaders } from '../Utils/f'
import axiosClient from './axios'

export const retrieveMixes = async (
  token: string
): Promise<AxiosResponse<Mixes>> =>
  await axiosClient.get('/mixes/', createApiHeaders(token))

export const createMix = async (
  token: string,
  body: CreateMixPostBody
): Promise<AxiosResponse<Mix>> =>
  await axiosClient.post('/mixes/', body, createApiHeaders(token))
