import { type FC } from 'react'
import EditCustomTrackDialog from './EditCustomTrackDialog'
import FooterSelectionBase from '../Controllers/CustomFooterSelection'

const CustomFooterSelection: FC = () => <FooterSelectionBase
  renderDialog={(selectedRow, dialogOpen, closeDialog) => (
    <EditCustomTrackDialog
      open={dialogOpen}
      onClose={closeDialog}
      customTrackData={selectedRow as CustomTrack | null}
    />
  )}
/>

export default CustomFooterSelection
