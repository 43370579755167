/* eslint-disable max-len */
const loader = {
  title: 'XCue',
  text: '...is Loading'
}
const login = {
  signin: 'Sign In',
  welcome: 'Welcome to XCue',
  language: 'Select Language',
  error: 'Authentication error'
}
const error = {
  title: 'Error :(',
  body: 'Oops, something went wrong!',
  button: 'Back to Home',
  logout: 'Back to LogIn Page'
}
const home = {
  title: 'XCue - Web',
  welcome: 'Browse the left menu and discover our web app!'
}
const user = {
  title: 'User Info',
  givenName: 'Name',
  familyName: 'Family Name',
  email: 'Email',
  id: 'User Id'
}
const NewMix = {
  buttonLabel: 'Create Mix'
}
const EditCustomTracks = {
  buttonLabel: 'Update Track'
}

const en = {
  error,
  login,
  loader,
  home,
  user,
  NewMix,
  EditCustomTracks
}

export default en
