import type { FC } from 'react'
import { type GridColDef, DataGrid, gridClasses } from '@mui/x-data-grid'
import { alpha, useTheme } from '@mui/material'
import CustomFooterSelectionTracks from './CustomFooterTracks'
import CustomFooterSelectionMixes from './CustomFooterMixes'

interface CustomTableProps {
  rows: any[]
  columns: GridColDef[]
  enableSelection?: boolean
  type: TableType
}

const CustomTable: FC<CustomTableProps> = ({ columns, rows, type, enableSelection = false }) => {
  const theme = useTheme()

  return <DataGrid
    rows={rows}
    columns={columns}
    pageSizeOptions={[10, 20, 30]}
    initialState={{
      pagination: {
        paginationModel: { page: 0, pageSize: 20 }
      }
    }}
    checkboxSelection={enableSelection}
    disableRowSelectionOnClick={!enableSelection}
    disableMultipleRowSelection
    hideFooterSelectedRowCount={enableSelection}
    slots={
      enableSelection
        ? { footer: type === 'custom_tracks' ? CustomFooterSelectionTracks : CustomFooterSelectionMixes }
        : {}
    }
    sx={{
      maxWidth: '94vw',
      bgcolor: theme.palette.secondary.main,
      [`& .${gridClasses.row}`]: {
        bgcolor: theme.palette.secondary.main,
        color: theme.palette.primary.main,
        '&:hover': {
          bgcolor: alpha(theme.palette.primary.main, 0.16) // Colore semi-trasparente al passaggio del mouse
        },
        '&.Mui-selected': {
          bgcolor: alpha(theme.palette.primary.main, 0.08), // Colore più intenso per la selezione
          '&:hover': {
            bgcolor: alpha(theme.palette.primary.main, 0.16) // Colore ancora più visibile durante l'hover su riga selezionata
          }
        }
      },
      '& .MuiDataGrid-columnHeaders': {
        bgcolor: `${theme.palette.secondary.main} !important`,
        color: theme.palette.primary.main
      },
      '& .MuiDataGrid-row--borderBottom': {
        bgcolor: `${theme.palette.secondary.main} !important`,
        color: theme.palette.primary.main
      },
      [`& .${gridClasses.cell}`]: {
        borderColor: theme.palette.secondary.light
      },
      '& .MuiTablePagination-toolbar': {
        bgcolor: theme.palette.secondary.main,
        color: theme.palette.primary.main
      },
      '& .MuiTablePagination-actions': {
        color: theme.palette.primary.main
      },
      '& .MuiSelect-icon': {
        color: theme.palette.primary.main
      },
      '& .MuiSvgIcon-root': {
        color: theme.palette.primary.main
      },
      '& .MuiDataGrid-footerContainer': {
        bgcolor: theme.palette.secondary.main,
        color: theme.palette.primary.main,
        borderTop: `1px solid ${theme.palette.secondary.light}`
      },
      '--DataGrid-rowBorderColor': theme.palette.secondary.light,
      borderColor: theme.palette.secondary.light
    }}
  />
}

export default CustomTable
