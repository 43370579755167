import { useCallback, type FC } from 'react'
import { useForm, Controller, type SubmitHandler } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { TextField, Button, Box, MenuItem, useTheme } from '@mui/material'
import { MusicNote } from '@mui/icons-material'
import { useAppDispatch } from '../Utils/store'
import { postMixes, setErrorMessage as setMixesErrorMessage } from '../Store/mixes'
import { useKindeAuth } from '@kinde-oss/kinde-auth-react'
import * as Yup from 'yup'

const mixSchema = Yup.object({
  a_track_id: Yup.number().required('Track A is required'),
  b_track_id: Yup.number().required('Track B is required'),
  a_offset: Yup.string().nullable(),
  b_offset: Yup.string().nullable(),
  mix_type: Yup.string().nullable(),
  weight: Yup.string().nullable(),
  delta_danceability: Yup.string().nullable(),
  delta_energy: Yup.string().nullable(),
  delta_BPM: Yup.string().nullable(),
  delta_genre: Yup.string().nullable(),
  direction: Yup.string().nullable(),
  emotion: Yup.string().nullable()
}) as Yup.ObjectSchema<CreateMix>

interface MixFormProps {
  aTrackId: number
  bTrackId: number
  resetSelections: () => void
}

const MixForm: FC<MixFormProps> = ({ aTrackId, bTrackId, resetSelections }) => {
  const theme = useTheme()
  const { getToken } = useKindeAuth()
  const dispatch = useAppDispatch()
  const { control, handleSubmit, reset } = useForm<CreateMix>({
    defaultValues: {
      a_track_id: aTrackId,
      b_track_id: bTrackId,
      a_offset: '',
      b_offset: '',
      mix_type: '',
      weight: '',
      delta_danceability: '',
      delta_energy: '',
      delta_BPM: '',
      delta_genre: '',
      direction: 'unset',
      emotion: ''
    },
    resolver: yupResolver(mixSchema)
  })

  const onSubmit: SubmitHandler<CreateMix> = useCallback(async (data: CreateMix) => {
    try {
      if (getToken != null) {
        const token = await getToken()
        if (token != null) {
          await dispatch(postMixes({
            token,
            body: {
              ...data,
              a_offset: (data.a_offset !== '' ? parseInt(data.a_offset) : null),
              b_offset: data.b_offset !== '' ? parseInt(data.b_offset) : null,
              delta_BPM: data.delta_BPM !== '' ? parseInt(data.delta_BPM) : null,
              delta_danceability: data.delta_danceability !== '' ? parseInt(data.delta_danceability) : null,
              delta_energy: data.delta_energy !== '' ? parseInt(data.delta_energy) : null,
              weight: data.weight !== '' ? parseInt(data.weight) : null,
              direction: data.direction === 'unset' ? null : data.direction === 'forward',
              emotion: data.emotion === '' ? null : data.emotion,
              mix_type: data.emotion === '' ? null : data.mix_type,
              delta_genre: data.delta_genre === '' ? null : data.delta_genre
            } satisfies CreateMixPostBody
          }))
          reset()
          resetSelections()
        }
      }
    } catch (e) {
      dispatch(setMixesErrorMessage(typeof e === 'string' ? e : String(e)))
    }
  }, [dispatch, getToken, reset, resetSelections])

  const sxStyle = {
    backgroundColor: 'inherit',
    borderRadius: 1,
    width: '45%',
    '& .MuiOutlinedInput-root': {
      color: theme.palette.primary.main, // Colore del testo
      '& fieldset': {
        borderColor: theme.palette.secondary.light // Colore del bordo iniziale
      },
      '&:hover fieldset': {
        borderColor: theme.palette.primary.light // Colore del bordo al passaggio del mouse
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.main // Colore del bordo quando il campo è selezionato
      }
    },
    '& .MuiInputLabel-root': {
      color: theme.palette.primary.main // Colore della label iniziale
    },
    '& .MuiInputLabel-root.Mui-focused': {
      color: theme.palette.primary.light // Colore della label quando il campo è selezionato
    },
    '& .MuiInputBase-input': {
      color: theme.palette.primary.main // Colore del testo inserito
    },
    '& .MuiSelect-iconOutlined': {
      color: theme.palette.primary.main
    },
    '& .MuiInputBase-input:-webkit-autofill': {
      boxShadow: `0 0 0px 1000px ${theme.palette.secondary.main} inset`, // forza sfondo (hotfix MUI common issue)
      WebkitTextFillColor: theme.palette.primary.main // forza testo (hotfix MUI common issue)
    }
  }
  const menuSx = {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.main
  }

  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  return <form onSubmit={handleSubmit(onSubmit)} style={{
    display: 'flex',
    flexDirection: 'column',
    gap: '4vh',
    alignItems: 'center',
    height: '96%',
    justifyContent: 'center'
  }}>
    <Box width='96%' flexDirection='row' display='flex' justifyContent='space-between' gap='2vw'>
      <Controller
        name="a_offset"
        control={control}
        render={({ field }) =>
          <TextField {...field} label="A Offset" type="number" sx={sxStyle} />
        }
      />
      <Controller
        name="b_offset"
        control={control}
        render={({ field }) => (
          <TextField {...field} label="B Offset" type="number" sx={sxStyle} />
        )}
      />
    </Box>
    <Box width='96%' flexDirection='row' display='flex' justifyContent='space-between' gap='2vw'>
      <Controller
        name="weight"
        control={control}
        render={({ field }) => (
          <TextField {...field} label="Weight" type="number" sx={sxStyle} />
        )}
      />
      <Controller
        name="mix_type"
        control={control}
        render={({ field }) => (
          <TextField {...field} label="Mix Type" sx={sxStyle} />
        )}
      />
    </Box>
    <Box width='96%' flexDirection='row' display='flex' justifyContent='space-between' gap='2vw'>
      <Controller
        name="delta_danceability"
        control={control}
        render={({ field }) => (
          <TextField {...field} label="∆ Danceability" type="number" sx={sxStyle} />
        )}
      />
      <Controller
        name="delta_energy"
        control={control}
        render={({ field }) => (
          <TextField {...field} label="∆ Energy" type="number" sx={sxStyle} />
        )}
      />
    </Box>
    <Box width='96%' flexDirection='row' display='flex' justifyContent='space-between' gap='2vw'>
      <Controller
        name="delta_BPM"
        control={control}
        render={({ field }) => (
          <TextField {...field} label="∆ BPM" type="number" sx={sxStyle} />
        )}
      />
      <Controller
        name="delta_genre"
        control={control}
        render={({ field }) => (
          <TextField {...field} label="∆ Genre" sx={sxStyle} />
        )}
      />
    </Box>
    <Box width='96%' flexDirection='row' display='flex' justifyContent='space-between' gap='2vw'>
      <Controller
        name="direction"
        control={control}
        render={({ field }) => (
          <TextField {...field} label="Direction" select sx={sxStyle} slotProps={{ select: { MenuProps: { PaperProps: { sx: menuSx } } } }}>
            <MenuItem value="unset">Unset</MenuItem>
            <MenuItem value="forward">Forward</MenuItem>
            <MenuItem value="backward">Backward</MenuItem>
          </TextField>
        )}
      />
      <Controller
        name="emotion"
        control={control}
        render={({ field }) => (
          <TextField {...field} label="Emotion" sx={sxStyle} />
        )}
      />
    </Box>
    <Button
      type="submit"
      variant="contained"
      color="primary"
      sx={{ fontWeight: 800, boxShadow: 10, width: '96%' }}
      data-testid="create-mix-button"
      endIcon={<MusicNote />}
    >
      Create Mix
    </Button>
  </form >
}

export default MixForm
