import { type AxiosResponse } from 'axios'
import { createApiHeaders } from '../Utils/f'
import axiosClient from './axios'

export const retrieveCustomTracks = async (
  token: string
): Promise<AxiosResponse<CustomTracks>> =>
  await axiosClient.get('/custom_tracks/', createApiHeaders(token))

export const editCustomTracks = async (
  token: string,
  body: EditCustomTracksPost,
  id: number
): Promise<AxiosResponse<CustomTrack>> =>
  await axiosClient.put(`/custom_tracks/${id}`, body, createApiHeaders(token))
