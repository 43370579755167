import { useCallback, useEffect, type FC } from 'react'
import { useForm, Controller, type SubmitHandler } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, Box, useTheme } from '@mui/material'
import { useAppDispatch } from '../Utils/store'
import { useKindeAuth } from '@kinde-oss/kinde-auth-react'
import { putCustomTracks, setErrorMessage } from '../Store/customTracks'
import { parseISO, format } from 'date-fns'
import * as Yup from 'yup'

interface EditCustomTrackDialogProps {
  open: boolean
  onClose: () => void
  customTrackData: CustomTrack | null
}

const editTrackSchema = Yup.object({
  title: Yup.string().required('Title is required'),
  artist: Yup.string().nullable(),
  album: Yup.string().nullable(),
  label: Yup.string().nullable(),
  version: Yup.string().nullable(),
  genre: Yup.string().nullable(),
  release_date: Yup.string().nullable(),
  bpm: Yup.string().nullable(),
  key: Yup.string().nullable(),
  energy: Yup.string().nullable(),
  hit: Yup.string().nullable(),
  danceability: Yup.string().nullable(),
  valence: Yup.string().nullable(),
  artwork_url: Yup.string().nullable()
})

const EditCustomTrackDialog: FC<EditCustomTrackDialogProps> = ({ open, onClose, customTrackData }) => {
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const { getToken } = useKindeAuth()
  const { control, handleSubmit, setValue, reset } = useForm<EditCustomTrack>({
    defaultValues: {
      title: '',
      artist: '',
      album: '',
      label: '',
      version: '',
      bpm: '',
      key: '',
      energy: '',
      hit: '',
      danceability: '',
      valence: '',
      artwork_url: '',
      release_date: '',
      genre: ''
    },
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    resolver: yupResolver(editTrackSchema as any)
  })

  useEffect(() => {
    if (customTrackData != null) {
      setValue('title', customTrackData.title ?? '')
      setValue('artist', customTrackData.artist ?? '')
      setValue('album', customTrackData.album ?? '')
      setValue('label', customTrackData.label ?? '')
      setValue('version', customTrackData.version ?? '')
      setValue('bpm', (customTrackData.bpm != null) ? customTrackData.bpm.toString() : '')
      setValue('key', customTrackData.key ?? '')
      setValue('energy', (customTrackData.energy != null) ? customTrackData.energy.toString() : '')
      setValue('hit', (customTrackData.hit != null) ? customTrackData.hit.toString() : '')
      setValue('danceability', (customTrackData.danceability != null) ? customTrackData.danceability.toString() : '')
      setValue('valence', (customTrackData.valence != null) ? customTrackData.valence.toString() : '')
      setValue('artwork_url', customTrackData.artwork_url ?? '')
      setValue('release_date', (customTrackData.release_date != null)
        ? format(parseISO(customTrackData.release_date), 'yyyy-MM-dd')
        : '')
      setValue('genre', (customTrackData.genre != null) && customTrackData.genre.length > 0 ? customTrackData.genre?.join(', ') : '')
    }
  }, [customTrackData, setValue])

  const onSubmit: SubmitHandler<EditCustomTrack> = useCallback(async (data: EditCustomTrack) => {
    try {
      if (customTrackData?.track_id != null && getToken != null) {
        const token = await getToken()
        if (token != null) {
          const body: EditCustomTracksPost = {
            title: data.title,
            artist: data.artist === '' ? null : data.artist,
            album: data.album === '' ? null : data.album,
            label: data.label === '' ? null : data.label,
            version: data.version === '' ? null : data.version,
            artwork_url: data.artwork_url === '' ? null : data.artwork_url,
            key: data.key === '' ? null : data.key,
            bpm: data.bpm !== '' ? parseFloat(data.bpm) : null,
            energy: data.energy !== '' ? parseInt(data.energy) : null,
            hit: data.hit !== '' ? parseInt(data.hit) : null,
            danceability: data.danceability !== '' ? parseInt(data.danceability) : null,
            release_date: data.release_date !== ''
              ? new Date(data.release_date).toISOString()
              : null,
            genre: data.genre.trim().length > 0
              ? data.genre.split(',').map(g => g.trim()).filter(g => g !== '')
              : [],
            valence: data.valence !== '' ? parseInt(data.valence) : null
          }
          await dispatch(putCustomTracks({
            token,
            body,
            id: customTrackData.track_id
          }))
          reset()
        }
      }
    } catch (e) {
      dispatch(setErrorMessage(typeof e === 'string' ? e : String(e)))
    } finally {
      onClose()
      reset()
    }
  }, [customTrackData?.track_id, dispatch, getToken, onClose, reset])

  const handleClose = useCallback(() => {
    onClose()
    reset()
  }, [onClose, reset])

  if (customTrackData == null) return null

  const sxStyle = {
    width: '48%',
    '& .MuiOutlinedInput-root': {
      color: theme.palette.primary.main,
      '& fieldset': { borderColor: theme.palette.secondary.light },
      '&:hover fieldset': { borderColor: theme.palette.primary.light },
      '&.Mui-focused fieldset': { borderColor: theme.palette.primary.main }
    },
    '& .MuiPaper-root': {
      backgroundColor: theme.palette.secondary.main
    },
    '& .MuiInputLabel-root': { color: theme.palette.primary.main },
    '& .MuiInputLabel-root.Mui-focused': { color: theme.palette.primary.light },
    '& .MuiInputBase-input': { color: theme.palette.primary.main },
    '& input[type="date"]::-webkit-calendar-picker-indicator': {
      backgroundColor: theme.palette.primary.main,
      borderRadius: '50%',
      cursor: 'pointer'
    }
  }

  return <Dialog
    open={open}
    onClose={handleClose}
    fullWidth
    maxWidth="sm"
  >
    <DialogTitle
      sx={{
        color: theme.palette.primary.main,
        textAlign: 'center',
        backgroundColor: theme.palette.secondary.main
      }}
    >
      Edit Custom Track
    </DialogTitle>
    {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
    <form onSubmit={handleSubmit(onSubmit)}
      style={{ display: 'flex', flexDirection: 'column', gap: '16px', padding: '16px', backgroundColor: theme.palette.secondary.main }}
    >
      <DialogContent>
        <Box display="flex" flexDirection="column" gap="2vh">
          <Box display="flex" justifyContent="space-between" gap="2vw">
            <Controller
              name="title"
              control={control}
              render={({ field }) => (
                <TextField {...field} label="Title" required sx={sxStyle} />
              )}
            />
            <Controller
              name="artist"
              control={control}
              render={({ field }) => (
                <TextField {...field} label="Artist" sx={sxStyle} />
              )}
            />
          </Box>
          <Box display="flex" justifyContent="space-between" gap="2vw">
            <Controller
              name="album"
              control={control}
              render={({ field }) => (
                <TextField {...field} label="Album" sx={sxStyle} />
              )}
            />
            <Controller
              name="label"
              control={control}
              render={({ field }) => (
                <TextField {...field} label="Label" sx={sxStyle} />
              )}
            />
          </Box>
          <Box display="flex" justifyContent="space-between" gap="2vw">
            <Controller
              name="version"
              control={control}
              render={({ field }) => (
                <TextField {...field} label="Version" sx={sxStyle} />
              )}
            />
            <Controller
              name="bpm"
              control={control}
              render={({ field }) => (
                <TextField {...field} label="BPM" type="number" sx={sxStyle} />
              )}
            />
          </Box>
          <Box display="flex" justifyContent="space-between" gap="2vw">
            <Controller
              name="key"
              control={control}
              render={({ field }) => (
                <TextField {...field} label="Key" sx={sxStyle} />
              )}
            />
            <Controller
              name="energy"
              control={control}
              render={({ field }) => (
                <TextField {...field} label="Energy" type="number" sx={sxStyle} />
              )}
            />
          </Box>
          <Box display="flex" justifyContent="space-between" gap="2vw">
            <Controller
              name="hit"
              control={control}
              render={({ field }) => (
                <TextField {...field} label="Hit" type="number" sx={sxStyle} />
              )}
            />
            <Controller
              name="danceability"
              control={control}
              render={({ field }) => (
                <TextField {...field} label="Danceability" type="number" sx={sxStyle} />
              )}
            />
          </Box>
          <Box display="flex" justifyContent="space-between" gap="2vw">
            <Controller
              name="valence"
              control={control}
              render={({ field }) => (
                <TextField {...field} label="Valence" type="number" sx={sxStyle} />
              )}
            />
            <Controller
              name="artwork_url"
              control={control}
              render={({ field }) => (
                <TextField {...field} label="Artwork URL" type="url" sx={sxStyle} />
              )}
            />
          </Box>
          <Box display="flex" justifyContent="space-between" gap="2vw">
            <Controller
              name="release_date"
              control={control}
              render={({ field }) => (
                <TextField {...field} label="Release Date" type="date" sx={sxStyle} />
              )}
            />
            <Controller
              name="genre"
              control={control}
              render={({ field }) => (
                <TextField {...field} label="Genre" sx={sxStyle} />
              )}
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center' }}>
        <Button
          onClick={handleClose}
          variant="contained"
          color="primary"
          sx={{ fontWeight: 800, boxShadow: 10, width: '40%', maxWidth: '220px' }}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          sx={{ fontWeight: 800, boxShadow: 10, width: '40%', maxWidth: '220px' }}
        >
          Save Changes
        </Button>
      </DialogActions>
    </form>
  </Dialog>
}

export default EditCustomTrackDialog
