import { Fragment, useCallback, useEffect, useMemo, useState, type FC } from 'react'
import { useTheme, Box, TextField, Typography, SpeedDial, SpeedDialAction, SpeedDialIcon } from '@mui/material'
import { type GridColDef } from '@mui/x-data-grid'
import { useKindeAuth } from '@kinde-oss/kinde-auth-react'
import { selectCustomTracks, getCustomTracks } from '../Store/customTracks'
import { setErrorMessage } from '../Store/users'
import { useAppDispatch, useAppSelector } from '../Utils/store'
import { setComponent } from '../Store/menu'
import { ArtTrack, MusicNote, BurstMode, Edit } from '@mui/icons-material'
import CompactTable from '../Components/CompactTable'
import MixForm from './MixForm'
import SuccessDialog from '../Components/SuccessDialog'

const columns: GridColDef[] = [
  { field: 'title', headerName: 'Title' },
  { field: 'artist', headerName: 'Artist' },
  { field: 'album', headerName: 'Album' },
  {
    field: 'genre',
    headerName: 'Genre',
    valueGetter: (params: string[]) => params.length > 0 ? params.join(', ') : ''
  },
  { field: 'duration', headerName: 'Duration (s)', type: 'number' },
  { field: 'bpm', headerName: 'BPM', type: 'number' },
  { field: 'key', headerName: 'Key' },
  { field: 'energy', headerName: 'Energy', type: 'number' },
  { field: 'last_update', headerName: 'Last Update' }
]

const NewMix: FC = () => {
  const { getToken } = useKindeAuth()
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const customTracks = useAppSelector(selectCustomTracks)
  const [selectedATrackId, setSelectedATrackId] = useState<number | null>(null)
  const [selectedBTrackId, setSelectedBTrackId] = useState<number | null>(null)
  const [selectionModelA, setSelectionModelA] = useState<number[]>([])
  const [selectionModelB, setSelectionModelB] = useState<number[]>([])
  const [showSuccessDialog, setShowSuccessDialog] = useState<boolean>(false)

  const [searchTermA, setSearchTermA] = useState<string>('')
  const [artistFilterA, setArtistFilterA] = useState<string>('')
  const [genreFilterA, setGenreFilterA] = useState<string>('')
  const [bpmRangeA, setBpmRangeA] = useState<[number, number]>([0, 999])
  const [searchTermB, setSearchTermB] = useState<string>('')
  const [artistFilterB, setArtistFilterB] = useState<string>('')
  const [genreFilterB, setGenreFilterB] = useState<string>('')
  const [bpmRangeB, setBpmRangeB] = useState<[number, number]>([0, 999])

  const [showFilters1A, setShowFilters1A] = useState<boolean>(false)
  const [showFilters2A, setShowFilters2A] = useState<boolean>(false)
  const [showFilters3A, setShowFilters3A] = useState<boolean>(false)
  const [showFilters1B, setShowFilters1B] = useState<boolean>(false)
  const [showFilters2B, setShowFilters2B] = useState<boolean>(false)
  const [showFilters3B, setShowFilters3B] = useState<boolean>(false)
  const toggleShowFilters1A = useCallback(() => { setShowFilters1A((showFilters1A) => !showFilters1A) }, [])
  const toggleShowFilters2A = useCallback(() => { setShowFilters2A((showFilters2A) => !showFilters2A) }, [])
  const toggleShowFilters3A = useCallback(() => { setShowFilters3A((showFilters3A) => !showFilters3A) }, [])

  const toggleShowFilters1B = useCallback(() => { setShowFilters1B((showFilters1B) => !showFilters1B) }, [])
  const toggleShowFilters2B = useCallback(() => { setShowFilters2B((showFilters2B) => !showFilters2B) }, [])
  const toggleShowFilters3B = useCallback(() => { setShowFilters3B((showFilters3B) => !showFilters3B) }, [])

  const actionsA = [
    { icon: <ArtTrack />, name: 'Artist', onclick: toggleShowFilters1A },
    { icon: <MusicNote />, name: 'Genre', onclick: toggleShowFilters2A },
    { icon: <BurstMode />, name: 'BPM', onclick: toggleShowFilters3A }
  ]

  const actionsB = [
    { icon: <ArtTrack />, name: 'Artist', onclick: toggleShowFilters1B },
    { icon: <MusicNote />, name: 'Genre', onclick: toggleShowFilters2B },
    { icon: <BurstMode />, name: 'BPM', onclick: toggleShowFilters3B }
  ]

  const filterTracks = useCallback((tracks: any[], searchTerm: string, artistFilter: string, genreFilter: string, bpmRange: number[]) => {
    return tracks.filter((track: { title: string, artist: string, genre: any[], bpm: number }) => {
      const matchesTitle = track.title?.toLowerCase().includes(searchTerm?.toLowerCase())
      const matchesArtist = track.artist?.toLowerCase().includes(artistFilter?.toLowerCase())
      const matchesGenre = (Boolean(genreFilter))
        ? track.genre?.some((g) => g?.toLowerCase().includes(genreFilter?.toLowerCase()))
        : true
      const matchesBPM = track.bpm >= bpmRange[0] && track.bpm <= bpmRange[1]
      return matchesTitle && matchesArtist && matchesGenre && matchesBPM
    })
  }, [])

  const setVisibility = useCallback((visibility: boolean) => {
    setShowSuccessDialog(visibility)
  }, [])

  const resetSelections = useCallback(() => {
    setSelectedATrackId(null)
    setSelectedBTrackId(null)
    setSelectionModelB([])
    setSelectionModelA([])
    setVisibility(true)
  }, [setVisibility])

  const goToMix = useCallback(async () => {
    setVisibility(false)
  }, [setVisibility])

  const goToMixes = useCallback(async () => {
    dispatch(setComponent('mixes'))
    setVisibility(false)
  }, [dispatch, setVisibility])

  useEffect(() => {
    (async () => {
      try {
        if (getToken != null) {
          const token = await getToken()
          if (token != null) {
            await dispatch(getCustomTracks({ token }))
          }
        }
      } catch (e) {
        dispatch(setErrorMessage(typeof e === 'string' ? e : String(e)))
      }
    })().catch((e) => {
      dispatch(setErrorMessage(typeof e === 'string' ? e : String(e)))
    })
  }, [dispatch, getToken])

  const filteredTracksA = useMemo(() => filterTracks(
    customTracks, searchTermA, artistFilterA, genreFilterA, bpmRangeA
  ), [filterTracks, customTracks, searchTermA, artistFilterA, genreFilterA, bpmRangeA])
  const filteredTracksB = useMemo(() => filterTracks(
    customTracks, searchTermB, artistFilterB, genreFilterB, bpmRangeB
  ), [artistFilterB, bpmRangeB, customTracks, filterTracks, genreFilterB, searchTermB])

  const sxStyle = {
    backgroundColor: 'inherit',
    borderRadius: 1,
    width: '15vw',
    '& .MuiOutlinedInput-root': {
      color: theme.palette.primary.main, // Colore del testo
      '& fieldset': {
        borderColor: theme.palette.secondary.light // Colore del bordo iniziale
      },
      '&:hover fieldset': {
        borderColor: theme.palette.primary.light // Colore del bordo al passaggio del mouse
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.main // Colore del bordo quando il campo è selezionato
      }
    },
    '& .MuiInputLabel-root': {
      color: theme.palette.primary.main // Colore della label iniziale
    },
    '& .MuiInputLabel-root.Mui-focused': {
      color: theme.palette.primary.light // Colore della label quando il campo è selezionato
    },
    '& .MuiInputBase-input': {
      color: theme.palette.primary.main // Colore del testo inserito
    }
  }

  return <Box
    sx={{
      width: '96%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.primary.main
    }}
    data-testid="newMix-component"
  >
    <Box
      sx={{
        width: '100%',
        height: '80px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        padding: '0 1%',
        boxShadow: `0px 2px 5px ${theme.palette.secondary.main}`,
        gap: '2vw'
      }}
    >
      <TextField
        variant="outlined"
        label="Filter for  Title"
        value={searchTermA}
        onChange={(e) => {
          setSearchTermA(e.target.value)
          setSelectedATrackId(null)
          setSelectionModelA([])
        }}
        sx={sxStyle}
      />
      <SpeedDial
        ariaLabel="Filter selection A"
        data-testid="filterSelectionA"
        sx={{ flexDirection: 'column', position: 'relative', top: '90px' }}
        icon={<SpeedDialIcon openIcon={<Edit />} />}
      >
        {actionsA.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={action.onclick}
            sx={{
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.secondary.main,
              '&:hover': {
                backgroundColor: theme.palette.primary.dark
              }
            }}
          />
        ))}
      </SpeedDial>
      {
        showFilters1A && <TextField
          variant="outlined"
          label="Filter for Artist"
          value={artistFilterA}
          onChange={(e) => {
            setArtistFilterA(e.target.value)
            setSelectedATrackId(null)
            setSelectionModelA([])
          }}
          sx={sxStyle}
        />
      }
      {
        showFilters2A && <TextField
          variant="outlined"
          label="Filter for Genre"
          value={genreFilterA}
          onChange={(e) => {
            setGenreFilterA(e.target.value)
            setSelectedATrackId(null)
            setSelectionModelA([])
          }}
          sx={sxStyle}
        />
      }
      {
        showFilters3A && <Fragment>
          <TextField
            variant="outlined"
            type="number"
            label="Filter for BPM Min"
            value={bpmRangeA[0]}
            onChange={(e) => {
              setBpmRangeA([Number(e.target.value), bpmRangeA[1]])
              setSelectedATrackId(null)
              setSelectionModelA([])
            }}
            sx={sxStyle}
          />
          <TextField
            variant="outlined"
            type="number"
            label="Filter for BPM Max"
            value={bpmRangeA[1]}
            onChange={(e) => {
              setBpmRangeA([bpmRangeA[0], Number(e.target.value)])
              setSelectedATrackId(null)
              setSelectionModelA([])
            }}
            sx={sxStyle}
          />
        </Fragment>
      }
    </Box>
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '2% 1%',
        height: '80%',
        backgroundColor: theme.palette.secondary.light
      }}
    >
      <Box
        sx={{
          width: '36%',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          overflowY: 'auto',
          backgroundColor: theme.palette.secondary.main,
          borderRadius: 2,
          padding: '1%',
          boxShadow: `0px 2px 5px ${theme.palette.secondary.dark}`
        }}
      >
        <CompactTable
          rows={filteredTracksA.map((row: { track_id: any }) => ({ id: row.track_id, ...row }))}
          columns={columns}
          setSelectedTrackId={setSelectedATrackId}
          selectionModel={selectionModelA}
          setSelectionModel={setSelectionModelA}
        />
      </Box>
      <Box
        sx={{
          width: '25%',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          overflowY: 'auto',
          backgroundColor: theme.palette.secondary.main,
          borderRadius: 2,
          padding: '1%',
          boxShadow: `0px 2px 5px ${theme.palette.secondary.dark}`,
          justifyContent: 'center'
        }}
      >
        {selectedATrackId != null && selectedBTrackId != null
          ? <MixForm
            aTrackId={selectedATrackId}
            bTrackId={selectedBTrackId}
            resetSelections={resetSelections}
          />
          : showSuccessDialog
            ? <SuccessDialog
              showSuccessDialog={showSuccessDialog}
              goToMix={goToMix}
              goToMixes={goToMixes}
            />
            : <Typography>Select tracks from both sides to create a mix</Typography>
        }
      </Box>
      <Box
        sx={{
          width: '36%',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          overflowY: 'auto',
          backgroundColor: theme.palette.secondary.main,
          borderRadius: 2,
          padding: '1%',
          boxShadow: `0px 2px 5px ${theme.palette.secondary.dark}`
        }}
      >
        <CompactTable
          rows={filteredTracksB.map((row: { track_id: any }) => ({ id: row.track_id, ...row }))}
          columns={columns}
          setSelectedTrackId={setSelectedBTrackId}
          selectionModel={selectionModelB}
          setSelectionModel={setSelectionModelB}
        />
      </Box>
    </Box>
    <Box
      sx={{
        width: '100%',
        height: '80px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 1%',
        boxShadow: `0px -2px 5px ${theme.palette.secondary.main}`,
        gap: '2vw'
      }}
    >
      {
        showFilters3B && <Fragment>
          <TextField
            variant="outlined"
            type="number"
            label="Filter for BPM Min"
            value={bpmRangeB[0]}
            onChange={(e) => {
              setBpmRangeB([Number(e.target.value), bpmRangeB[1]])
              setSelectedBTrackId(null)
              setSelectionModelB([])
            }}
            sx={sxStyle}
          />
          <TextField
            variant="outlined"
            type="number"
            label="Filter for BPM Max"
            value={bpmRangeB[1]}
            onChange={(e) => {
              setBpmRangeB([bpmRangeB[0], Number(e.target.value)])
              setSelectedBTrackId(null)
              setSelectionModelB([])
            }}
            sx={sxStyle}
          />
        </Fragment>
      }
      {
        showFilters2B && <TextField
          variant="outlined"
          label="Filter for Genre"
          value={genreFilterB}
          onChange={(e) => {
            setGenreFilterB(e.target.value)
            setSelectedBTrackId(null)
            setSelectionModelB([])
          }}
          sx={sxStyle}
        />
      }

      {
        showFilters1B && <TextField
          variant="outlined"
          label="Filter for Artist"
          value={artistFilterB}
          onChange={(e) => {
            setArtistFilterB(e.target.value)
            setSelectedBTrackId(null)
            setSelectionModelB([])
          }}
          sx={sxStyle}
        />
      }
      <SpeedDial
        ariaLabel="Filter selection B"
        sx={{ flexDirection: 'column-reverse', position: 'relative', bottom: '95px' }}
        data-testid="filterSelectionB"
        icon={<SpeedDialIcon openIcon={<Edit />} />}
      >
        {actionsB.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={action.onclick}
            sx={{
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.secondary.main,
              '&:hover': {
                backgroundColor: theme.palette.primary.dark
              }
            }}
          />
        ))}
      </SpeedDial>
      <TextField
        variant="outlined"
        label="Filter for Title"
        value={searchTermB}
        onChange={(e) => {
          setSearchTermB(e.target.value)
          setSelectedBTrackId(null)
          setSelectionModelB([])
        }}
        sx={sxStyle}
      />
    </Box>
  </Box>
}

export default NewMix
