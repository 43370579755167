import { useEffect, type FC } from 'react'
import { useKindeAuth } from '@kinde-oss/kinde-auth-react'
import { type GridColDef } from '@mui/x-data-grid'
import { Box, Paper, Typography, useTheme } from '@mui/material'
import { useAppDispatch, useAppSelector } from '../Utils/store'
import { getCustomTracks, selectCustomTracks, setErrorMessage } from '../Store/customTracks'
import CustomTable from './CustomTable'

const CustomTracks: FC = () => {
  const { getToken } = useKindeAuth()
  const dispatch = useAppDispatch()
  const customTracks = useAppSelector(selectCustomTracks)
  const theme = useTheme()

  useEffect(() => {
    (async () => {
      try {
        if (getToken != null) {
          const token = await getToken()
          if (token != null) {
            await dispatch(getCustomTracks({ token }))
          }
        }
      } catch (e) {
        dispatch(setErrorMessage(typeof e === 'string' ? e : String(e)))
      }
    })()
      .catch(e => { dispatch(setErrorMessage(typeof e === 'string' ? e : String(e))) })
  }, [dispatch, getToken])

  const columns: GridColDef[] = [
    { field: 'title', headerName: 'Title' },
    { field: 'artist', headerName: 'Artist' },
    { field: 'album', headerName: 'Album' },
    {
      field: 'genre',
      headerName: 'Genre',
      valueGetter: (params: string[]) => params.length > 0 ? params.join(', ') : ''
    },
    { field: 'duration', headerName: 'Duration (s)', type: 'number' },
    { field: 'bpm', headerName: 'BPM', type: 'number' },
    { field: 'key', headerName: 'Key' },
    { field: 'energy', headerName: 'Energy', type: 'number' },
    { field: 'last_update', headerName: 'Last Update' }
  ]

  return <Paper
    sx={{
      width: '100%',
      bgcolor: theme.palette.secondary.light,
      color: theme.palette.primary.main,
      padding: 2,
      boxShadow: theme.shadows[3]
    }}
  >
    {
      customTracks.length > 0
        ? <CustomTable rows={customTracks.map(row => ({ id: row.track_id, ...row }))} columns={columns} enableSelection type='custom_tracks' />
        : <Box
          sx={{
            textAlign: 'center',
            padding: '20px',
            bgcolor: theme.palette.secondary.main,
            color: theme.palette.primary.main
          }}
        >
          <Typography variant="h6">Non ci sono custom tracks disponibili.</Typography>
          <Typography variant="body1" sx={{ marginBottom: 2 }}>
            Collegale dall&apos;app!
          </Typography>
        </Box>
    }
  </Paper>
}

export default CustomTracks
