import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material'
import { useCallback, type FC } from 'react'

interface EditMixDialogProps {
  open: boolean
  onClose: () => void
  mixData: Mix | null
}

const EditMixDialog: FC<EditMixDialogProps> = ({ open, onClose, mixData }) => {
  const handleClose = useCallback(() => {
    onClose()
  }, [onClose])

  if (mixData == null) return null

  return <Dialog
    open={open}
    onClose={handleClose}
    fullScreen
  >
    <DialogTitle>Modifica Mix</DialogTitle>
    <DialogContent>
      quo
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose} color="secondary">
        Annulla
      </Button>
    </DialogActions>
  </Dialog>
}

export default EditMixDialog
